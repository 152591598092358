import Head from 'next/head';
import Header from './Header';
import Footer from './Footer';
import { generateUrl, getCurrentBreakpoint, FBDataLayer, useStorage, setBrowserId, unauthorizedErrorHandler, sleep } from "../../utils/Helper";
import Button from '../forms/Button';
import { useState, useEffect, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Sentry from "@sentry/nextjs";
import ButtonOverlay from './ButtonOverlay';
import UnitCompareBanner from './banners/UnitCompareBanner';
import classes from './Master.module.css'
import { _getCookie, _setCookie } from '../../utils/Cookie';
import axios from 'axios';
import DefaultAxios from '../../utils/DefaultAxios';
import Cookies from 'js-cookie';
import Image from 'next/image';

declare global {
    interface Window {
        location: Location;
    }
}

interface IProps {
    children: React.ReactNode
    title: string
    description: string
    keyword?: string
    image?: string
    url: string
    lang: any
    langCode: string
    isTransparent?: boolean
    isBlue?: boolean
    withoutGA?: boolean
    header?: React.ReactNode
    noFooter?: boolean
    usePadding?: boolean
    withAds?: boolean
    isRestrictedFullWidth?: boolean
    breadcrumb?: IInputBreadcrumb
    sidebarZIndex?: string
    backgroundColor?: string
    sellerPage?: boolean
    is_noindex?: boolean
    withoutBanner?: boolean
    withoutFeedback?: boolean
    feedbackMobileExtraMargin?: boolean
    withCompareUnitTutorialBanner?: boolean
    withoutButtonOverlay?: boolean
    disableTrackUrl?: boolean
    extraFooter?: boolean
    ldJsons?: Record<string, any>[]
    preloadImage?: string | string[]
}

export interface IInputBreadcrumb {
    [key: string]: string
}

interface IBreadcrumb {
    "@context": string
    "@type": string
    "itemListElement": {
        "@type": "ListItem"
        position: number
        name: string
        item?: string
    }[]
}

export default function Master({ children, extraFooter, ldJsons, title, description, keyword, image, url, lang, isTransparent, isBlue, langCode, withoutGA, header, noFooter, usePadding, withAds, isRestrictedFullWidth, breadcrumb, sidebarZIndex, backgroundColor, sellerPage, is_noindex, withoutBanner, withoutFeedback, feedbackMobileExtraMargin, withCompareUnitTutorialBanner, withoutButtonOverlay, disableTrackUrl, preloadImage }: IProps) {
    const [isAdsOpen, setAdsOpen] = useState(false)
    const [traceId] = useState(Sentry.getCurrentHub().getScope()?.getSpan()?.toTraceparent());

    useEffect(() => {
        const breakpoint = getCurrentBreakpoint()
        setIsMobile(breakpoint === 'xs' || breakpoint === 'sm')
        setAdsOpen(withAds ? (breakpoint !== 'xs' && breakpoint !== 'sm') : false)
    }, [])

    const constructBreadcrumb = (breadcrumb: IInputBreadcrumb) => {
        let output: IBreadcrumb = {
            "@context": "https://schema.org",
            "@type": "BreadcrumbList",
            "itemListElement": []
        }

        const length = Object.entries(breadcrumb).length - 1

        Object.entries(breadcrumb).forEach(([key, value], index) => {
            output.itemListElement.push({
                "@type": "ListItem",
                position: index + 1,
                name: key,
                item: index >= length ? undefined : value
            })
        })

        return output
    }

    const generatedLdJsons = useMemo(() => {
        if (ldJsons && ldJsons.length) {
            return ldJsons.map(ldJson => JSON.stringify(ldJson))
        } else {
            return []
        }
    }, [ldJsons])

    const renderFacebookPixelScript = () => {
        FBDataLayer(window.location.href, title)
    }


    const [banner, setBanner] = useState(false)
    const [isUnitCompareTutorialBannerOpen, setIsUnitCompareTutorialBannerOpen] = useState(false)
    const [isMobile, setIsMobile] = useState(false)
    const handleBanner = () => {
        setBanner(false)
        try {
            localStorage.setItem('bannerClosed2', '1')
        } catch {
            // console.error('Cookies disabled')
        }
    }


    useEffect(() => {
        useStorage(() => {
            const bannerClosed2 = localStorage.getItem('bannerClosed2') ? true : false
            const agentPage = window.location.href.includes('agent') ? true : false
            const unitCompareTutorialBannerClosed = withCompareUnitTutorialBanner ? localStorage.getItem('unit-compare-tutorial-banner-closed') ? true : false : true

            if (!bannerClosed2 && !agentPage && unitCompareTutorialBannerClosed) {
                setTimeout(() => {
                    setBanner(true)
                }, 10000);
            } else {
                setBanner(false)
            }
        })
    }, [withCompareUnitTutorialBanner])

    useEffect(() => {
        useStorage(() => {
            const unitCompareTutorialBannerClosed = withCompareUnitTutorialBanner ? localStorage.getItem('unit-compare-tutorial-banner-closed') ? true : false : true

            setTimeout(() => {
                setIsUnitCompareTutorialBannerOpen(!unitCompareTutorialBannerClosed)
            }, 10000);
        })
    }, [withCompareUnitTutorialBanner])

    useEffect(() => {
        setBrowserId()
        renderFacebookPixelScript()
    }, [])

    const trackUrl = async (url: string): Promise<boolean> => {
        const browserId = _getCookie('browser_id')

        if (!browserId) {
            await sleep(1000)
            return trackUrl(url)
        } else {
            try {
                const res = await axios.post(`${process.env.NEXT_PUBLIC_PHP_API_URL}/page-tracker`, {
                    page: url.split('?')[0],
                    browser_id: browserId,
                })

                return true
            } catch (e) {
                return false
            }
        }
    }

    useEffect(() => {
        if (url && !disableTrackUrl) {
            trackUrl(url)
        }
    }, [url, disableTrackUrl])

    const handleRedirectBanner = () => {
        try {
            localStorage.setItem('bannerClosed2', '1')
        } catch {
            // console.error('Cookies disabled')
        }
        window.location.href = 'https://ecatalog.sinarmasland.com/infiniteliving'
    }

    const onUnitCompareTutorialBannerClose = () => {
        setIsUnitCompareTutorialBannerOpen(false)
        useStorage(() => {
            localStorage.setItem('unit-compare-tutorial-banner-closed', '1')
        })
    }

    const defaultMicrodata = {
        "@context": "https://schema.org/",
        "@type": "Corporation",
        "name": "Sinar Mas Land",
        "logo": "https://ecatalog.sinarmasland.com/static/images/svg/sinarmas_logo.svg",
        "location": "Sinar Mas Land Plaza",
        "address": "Jl. Grand Boulevard, BSD City Tangerang, Banten, Indonesia 15345",
        "slogan": "building for a better future",
        "parentOrganization": "Sinar Mas",
    }

    const checkTodayVisit = () => {
        const token = localStorage.getItem('token')

        if (token) {
            const todayVisit = _getCookie('today_visit')
            if (!todayVisit) {
                const expires = new Date()
                expires.setHours(23, 59, 59, 999)

                DefaultAxios.post(`${process.env.NEXT_PUBLIC_PHP_API_URL}/account-visit-tracker`)
                    .then(res => {
                        Cookies.set('today_visit', 'true', {
                            expires,
                        })
                    })
                    .catch(err => {
                        console.log('visitor tracking failed')
                    })

            }
        }
    }

    const accountPageTrack = (path: string) => {
        const token = localStorage.getItem('token')
        const typeLogin = localStorage.getItem('type_login')
        if (token && typeLogin !== 'agent') {

            DefaultAxios.post(`${process.env.NEXT_PUBLIC_PHP_API_URL}/track-visit-page`, {
                page: path,
            })
                .then(res => { })
                .catch(err => {
                    console.log('account tracking failed')
                })
        }
    }

    useEffect(() => {
        accountPageTrack(url)
    }, [url])

    useEffect(() => {
        checkTodayVisit()
    }, [])

    return (
        <>
            <Head>
                <meta charSet="UTF-8" />
                <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
                <meta name="viewport" content="height=device-height,width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
                <title>{title}</title>
                {
                    (is_noindex || process.env.NEXT_PUBLIC_FORCE_NOINDEX) &&
                    <meta name="robots" content="noindex, nofollow" />
                }
                <meta name="google-site-verification" content={process.env.NEXT_PUBLIC_GOOGLE_SITE_VERIFICATION} />
                <meta name="description" content={description} />
                <meta name="keywords" content={keyword || ''} />
                <meta name="ahrefs-site-verificaftion" content={process.env.NEXT_PUBLIC_AHREFS_SITE_VERIFICATION} />
                {/* <meta property="fb:app_id" content="260180081106004" /> */}
                <meta property="og:type" content="website" />
                <meta property="og:site_name" content="Sinar Mas Land" />
                <meta property="og:url" content={generateUrl(langCode, url)} />
                <meta property="og:title" content={title} />
                <meta property="og:description" content={description || 'Temukan 7414 unit apartemen 1BR/ 2BR/ 3BR disewakan di Jakarta, harga mulai dari 3.340.000x12bln. ✓12x cicilan, ✓360 virtual tour.'} />
                <meta name="og:keyword" content={keyword || ''} />
                <meta property="og:image" content={image || `${process.env.NEXT_PUBLIC_MAINSITE_URL}/static/images/default-thumbnail-unit.jpg`} />

                <meta name="twitter:site" content="@sinarmas_land" />
                <meta name="twitter:creator" content="@sinarmas_land" />
                <meta name="twitter:title" content={title} />
                <meta name="twitter:description" content={description || 'Temukan 7414 unit apartemen 1BR/ 2BR/ 3BR disewakan di Jakarta, harga mulai dari 3.340.000x12bln. ✓12x cicilan, ✓360 virtual tour.'} />
                <meta name="twitter:keyword" content={keyword || ''} />
                <meta name="twitter:image" content={image || `${process.env.NEXT_PUBLIC_MAINSITE_URL}/static/images/default-thumbnail-unit.jpg`} />
                <meta name="twitter:card" content="summary" />

                <meta name="sentry-trace" content={`${traceId}`} />

                <link rel="canonical" href={generateUrl(langCode, url)} />

                <meta name='application-name' content='Sinar Mas Land' />
                <meta name='apple-mobile-web-app-capable' content='yes' />
                <meta name='apple-mobile-web-app-status-bar-style' content='default' />
                <meta name='apple-mobile-web-app-title' content='Sinar Mas Land' />
                <meta name='format-detection' content='telephone=no' />
                <meta name='mobile-web-app-capable' content='yes' />
                <meta name='theme-color' content='#FFFFFF' />
                <link rel='manifest' href='/manifest.json' />

                <link rel="shortcut icon" href={`${process.env.NEXT_PUBLIC_MAINSITE_URL}/favicon.png`} type="image/x-icon" />
                <link rel="apple-touch-icon" href={`${process.env.NEXT_PUBLIC_MAINSITE_URL}/mobile-favicon.png`} />
                <link rel="apple-touch-icon" sizes="72x72" href={`${process.env.NEXT_PUBLIC_MAINSITE_URL}/mobile-favicon.png`} />
                <link rel="apple-touch-icon" sizes="114x114" href={`${process.env.NEXT_PUBLIC_MAINSITE_URL}/mobile-favicon.png`} />
                <link rel="apple-touch-icon" sizes="128x128" href={`${process.env.NEXT_PUBLIC_MAINSITE_URL}/mobile-favicon.png`} />

                <link rel="preconnect" href="https://www.google-analytics.com" />
                <link rel="preconnect" href="https://fonts.gstatic.com/" />
                <link href="https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400;600;700;800&display=swap" rel="stylesheet" />

                {
                    preloadImage ?
                        typeof preloadImage === 'string' ?
                            <link rel="preload" href={preloadImage} as="image" />
                            :
                            preloadImage.map((preImage, index) =>
                                <link key={index} rel="preload" href={preImage} as="image" />
                            )
                        :
                        null
                }

                {
                    !!breadcrumb ?
                        <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(constructBreadcrumb(breadcrumb)) }} />
                        : <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(defaultMicrodata) }} />
                }

                {
                    generatedLdJsons.length &&
                    generatedLdJsons.map(ldJsons => (
                        <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: ldJsons }} />
                    ))
                }

                {/* Google Analytics */}

                {
                    withoutGA ?
                        null
                        :
                        <>

                            <script dangerouslySetInnerHTML={{
                                __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                                    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                                    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                                    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                                })(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID}');`,
                            }}>
                            </script>
                            <script async src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID}`}></script>
                            <script
                                dangerouslySetInnerHTML={{
                                    __html: `
                                    window.dataLayer = window.dataLayer || [];
                                    function gtag(){dataLayer.push(arguments);}
                                    gtag('js', new Date());
                                
                                    gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID}');
                                `
                                }}
                            />
                            {
                                process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID ?
                                    <>
                                        <script
                                            dangerouslySetInnerHTML={{
                                                __html: `!function(f,b,e,v,n,t,s)
                                                {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                                                n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                                                if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                                                n.queue=[];t=b.createElement(e);t.async=!0;
                                                t.src=v;s=b.getElementsByTagName(e)[0];
                                                s.parentNode.insertBefore(t,s)}(window, document,'script',
                                                'https://connect.facebook.net/en_US/fbevents.js');
                                                fbq('init', '${process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID}');
                                                fbq('track', 'PageView');`
                                            }}
                                        />

                                        <noscript>
                                            <img height="1" width="1" style={{ display: 'none' }}
                                                src={`https://www.facebook.com/tr?id=${process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID}&ev=PageView&noscript=1`}
                                                alt="pixel"
                                            />
                                        </noscript>
                                    </>
                                    :
                                    null
                            }
                            {/* script to reduce Bounce */}
                            <script dangerouslySetInnerHTML={{
                                __html: `setTimeout(function(){  
                                setInterval(function(){ gtag('event', 'Open', {'event_category': 'TimeOnPage'}); }, 5000);
                                setInterval(function(){ if(!document.hidden) gtag('event', 'Visible', {'event_category': 'TimeOnPage'}); }, 5000);
                                }, 60000);`
                            }}>
                            </script>
                        </>
                }
            </Head>
            <div className={`relative transition-all duration-150 ease-linear ${backgroundColor || ''}`}>
                {
                    withoutButtonOverlay ? null :
                        <ButtonOverlay
                            withoutFeedback={withoutFeedback}
                            feedbackMobileExtraMargin={feedbackMobileExtraMargin}
                        />
                }
                {
                    header || <Header isTransparent={isTransparent} isBlue={isBlue} langCode={langCode} lang={lang} withAds={isAdsOpen} isRestrictedFullWidth={isRestrictedFullWidth} sidebarZIndex={sidebarZIndex} />
                }
                {
                    (banner && !withoutBanner) &&
                    <div className={`fixed min-w-full flex justify-center items-center right-0 left-0 h-screen transition-all duration-150 ease-linear ${classes.banner}`}>
                        <div className='px-5 md:px-0'>
                            <div className='text-right'>
                                <FontAwesomeIcon icon="times" size="2x" width="0" className="cursor-pointer text-white mb-2" onClick={handleBanner} />
                            </div>
                            <div
                                className='lg:hidden m-auto cursor-pointer relative px-5'
                                style={{ width: '90vw', minHeight: '400px' }}
                                // style={{ width: '90vw', minHeight: '250px' }}
                                onClick={() => handleRedirectBanner()}
                            >
                                <Image
                                    loading='lazy'
                                    src="/static/images/smart-move/pop-up-banner-mobile.jpg"
                                    alt='banner'
                                    layout='fill'
                                    objectFit='contain'
                                />
                            </div>
                            <div
                                className='hidden lg:block m-auto cursor-pointer relative'
                                onClick={() => handleRedirectBanner()}
                                style={{ width: '80vw', aspectRatio: '10/4' }}
                            >
                                <Image
                                    loading='lazy'
                                    src="/static/images/smart-move/pop-up-banner-desktop.jpg"
                                    alt='banner'
                                    layout='fill'
                                    objectFit='contain'
                                />
                            </div>
                            {/* <img loading='lazy' onClick={() => handleRedirectBanner()} src="/static/images/smart-move/pop-up-banner-mobile-2.jpg" className='lg:hidden m-auto cursor-pointer' alt='banner' /> */}

                            <div className='text-center mt-5'>
                                <Button onClick={() => handleRedirectBanner()} fontColor="text-black-48" fontSize="text-lg" fontWeight="font-semibold" rounded="rounded-sm" classes={`px-12 py-2 rounded border bg-white`}>
                                    Learn More
                                </Button>
                            </div>
                        </div>
                    </div>
                }
                {
                    (isUnitCompareTutorialBannerOpen && withCompareUnitTutorialBanner) &&
                    <UnitCompareBanner
                        isMobile={isMobile}
                        onUnitCompareTutorialBannerClose={onUnitCompareTutorialBannerClose}
                    />
                }
                <div className={`min-h-screen flex flex-col ${!isTransparent ? usePadding ? 'pt-12' : 'mt-12' : ''} ${!isTransparent && usePadding ? classes['pt-56'] : classes['pt-0']} ${!isTransparent && !usePadding ? classes['mt-56'] : classes['mt-0']}`}>
                    {children}
                    {
                        !noFooter ?
                            <Footer lang={lang} sellerPage={sellerPage} extraFooter={extraFooter} />
                            : null
                    }
                </div>
            </div>
            <div id='ZN_8jG8Rk9hy5ss7ZA'></div>
        </>
    )
}
